import { Carousel } from 'react-responsive-carousel';

import { Colors } from 'enums/colors';

import BydEvSubscriptionBanner from './BydEvSubscriptionBanner/BydEvSubscriptionBanner';
import CheryOmoda5Banner from './CheryOmoda5Banner/CheryOmoda5Banner';
import MGSubscriptionBanner from './MGSubscriptionBanner/MGSubscriptionBanner';
import NewCarBanner from './NewCarBanner/NewCarBanner';
import TemporaryBanner from './TemporaryBanner/TemporaryBanner';
import YourCarBanner from './YourCarBanner/YourCarBanner';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Header = () => {
  const defDotsStyle = {
    width: '16px',
    height: '16px',
    fontSize: '30px',
    marginLeft: 15,
    backgroundColor: 'white',
    cursor: 'pointer',
    border: '1px solid #AAA',
    opacity: 1,
    boxShadow: 'none',
  };
  const defArrowsStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    fontSize: '30px',
    top: '50%',
    bottom: 0,
    marginTop: 0,
    borderRadius: '55px',
    backgroundColor: `${Colors.coral}`,
    marginLeft: '8px',
    marginRight: '8px',
    opacity: 1,
  };

  return (
    <div className="container mx-auto md:h-auto ">
      <Carousel
        autoPlay
        infiniteLoop
        transitionTime={500}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        emulateTouch
        preventMovementUntilSwipeScrollTolerance
        renderIndicator={(onClickHandler, isSelected, index) => {
          const style = isSelected
            ? {
                ...defDotsStyle,
                border: `3px solid ${Colors.coral}`,
                backgroundColor: `${Colors.coral}`,
                opacity: 1,
              }
            : { ...defDotsStyle };
          return (
            <li
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              style={style}
              className="dot"
              value="0"
              role="button"
              tabIndex={index}
              aria-label={`slide item ${index}`}
            />
          );
        }}
        renderArrowPrev={onClickHandler => {
          return (
            <button
              style={defArrowsStyle}
              type="button"
              aria-label="previous slide / item"
              className="control-arrow control-prev"
              onClick={onClickHandler}
            />
          );
        }}
        renderArrowNext={onClickHandler => {
          return (
            <button
              style={defArrowsStyle}
              type="button"
              aria-label="next slide / item"
              className="control-arrow control-next"
              onClick={onClickHandler}
            />
          );
        }}
      >
        <div className="min-h-[680px] md:min-h-[790px] lg:min-h-0 ">
          <TemporaryBanner />
        </div>
        <div className="min-h-[680px] md:min-h-[790px] lg:min-h-0 ">
          <BydEvSubscriptionBanner />
        </div>
        <div className="min-h-[680px] md:min-h-[790px] lg:min-h-0 ">
          <CheryOmoda5Banner />
        </div>
        <div className="min-h-[680px] md:min-h-[790px] lg:min-h-0 ">
          <MGSubscriptionBanner />
        </div>
        <div className="min-h-[680px] md:min-h-[790px] lg:min-h-0 ">
          <NewCarBanner />
        </div>
        <YourCarBanner />
      </Carousel>
    </div>
  );
};

export default Header;
