const TemporaryBanner = () => {
  return (
    <div className="mx-auto flex flex-col-reverse items-center justify-start md:bg-none lg:h-[450px] lg:items-start lg:justify-center lg:bg-[url('/images/mid-year-sale.png')] lg:bg-cover lg:bg-left lg:bg-no-repeat lg:px-12 lg:py-5 xl:h-[500px] xl:bg-contain xl:bg-top xl:py-4 2xl:h-[545px] ">
      <div className="lg:hidden">
        <img
          className="p-0 object-cover object-center w-full"
          src="/images/mid-year-sale-mobile.png"
          alt="Three slices of different cars completing each other"
          width={866}
          height={410}
        />
      </div>
    </div>
  );
};

export default TemporaryBanner;
